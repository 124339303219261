<template>
  <div class="dynamic-tables">
    <Breadcrumbs v-bind:breadcrumbs="breadcrumbs" />

    <Widget>
      <DataTable
        class="p-datatable-sm"
        :value="getReportTemplateImages"
        :filters="filters"
        :loading="loading"
        :rows="1000"
        dataKey="id"
        :rowHover="true"
      >
        <template #header>
          <div class="table-header">
            <div class="table-header-left">
              <h1 class="page-title">{{ $t('reportTemplateImages') }}</h1>
            </div>
          </div>
        </template>
        <template #empty>
          {{ $t('no_data_found') }}
        </template>
        <template #loading>
          {{ $t('loading') }}
        </template>

        <Column field="number" header="#" sortable filterMatchMode="contains">
          <template #body="slotProps">
            <router-link
              :to="{
                name: 'ReportTemplateImageEditPage',
                params: { reportTemplateImageNumber: slotProps.data.number },
              }"
              >{{ slotProps.data.number.toString().padStart(4, '0') }}</router-link
            >
          </template>
        </Column>

        <Column field="category" :header="$t('category')" sortable filterMatchMode="contains">
          <template #body="slotProps">
            {{ $t(slotProps.data.category) }}
          </template>
        </Column>
      </DataTable>
    </Widget>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import Widget from '@/components/Widget/Widget';
import Breadcrumbs from '@/components/Breadcrumbs';
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';

export default {
  components: {
    Breadcrumbs,
    DataTable,
    Widget,
    Column,
  },
  data() {
    return {
      loading: true,
      filters: {},
    };
  },
  computed: {
    ...mapGetters(['isLoading', 'getReportTemplateImages']),
    breadcrumbs() {
      return [
        { name: 'Home', route: { name: 'home' } },
        { name: 'Berichte' },
        { name: 'Beispielbilder' },
      ];
    },
  },
  methods: {
    ...mapActions(['fetchReportTemplateImages']),
  },
  async created() {
    await this.fetchReportTemplateImages();
    this.loading = false;
  },
};
</script>

<style scoped lang="scss">
::v-deep .p-datatable .p-datatable-thead > tr > th:nth-child(1),
::v-deep .p-datatable .p-datatable-tbody > tr > td:nth-child(1) {
  width: 60px;
  text-align: center;
}
::v-deep .p-datatable .p-datatable-thead > tr > th:nth-child(3),
::v-deep .p-datatable .p-datatable-tbody > tr > td:nth-child(3) {
  width: 80px;
  text-align: center;
}
::v-deep .p-datatable .p-datatable-thead > tr > th:nth-child(n + 5),
::v-deep .p-datatable .p-datatable-tbody > tr > td:nth-child(n + 5) {
  width: 100px;
  text-align: center;
}
.table-header {
  display: flex;
  justify-content: space-between;
}
.table-header-left {
  display: flex;
  justify-content: flex-start;
}
.table-header-right {
  display: flex;
  justify-content: flex-end;
  column-gap: 1em;
}
/** Fix Dropdown overlay */
::v-deep .p-datatable-scrollable-header {
  overflow: visible;
}
</style>
